import Swal from 'sweetalert2';
import { ICustomNotification } from '../types/notification';

export default function notificationSuccess({
  title,
  text = '',
}: ICustomNotification) {
  return Swal.fire({
    icon: 'success',
    confirmButtonColor: 'green',
    cancelButtonColor: 'green',
    title,
    text,
  });
}
