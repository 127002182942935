import Swal from 'sweetalert2';

interface ISimpleNotificationConfirm {
  title: string;
  text: string;
  confirmText?: string;
  cancelText?: string;
  confirmButtonColor?: string;
  iconColor?: string;
}

export default async function simpleNotificationConfirm({
  title,
  text,
  confirmText = 'Confirmar',
  cancelText = 'Cancelar',
  confirmButtonColor = 'green',
  iconColor = 'gray',
}: ISimpleNotificationConfirm): Promise<Boolean> {
  const notifResp = await Swal.fire({
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: confirmText,
    cancelButtonText: cancelText,
    confirmButtonColor,
    iconColor,
    // color: 'red',
    title,
    text,
  });

  return notifResp.isConfirmed;
}
