import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  Box,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Email, Lock, Person } from "@mui/icons-material";
import { api } from "../../server/api";
import notificationSuccess from "../../services/Notification/Success";
import { Itherapist } from "../../services/types/therapist";

interface IPropsModal {
  open: any;
  setOpen: any;
  handleClickOpen: any;
  handleClose: any;
  afterEdit: any;
  therapistSelected: Itherapist;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal({
  open,
  setOpen,
  handleClickOpen,
  handleClose,
  afterEdit,
  therapistSelected,
}: IPropsModal) {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  useEffect(() => {
    setNome(therapistSelected?.nome || "");
    setEmail(therapistSelected?.email || "");
    setSenha("");
  }, [therapistSelected]);

  const editPatient = async () => {
    try {
      if (!nome || !email || !senha) return;

      await api.put(`/therapist/${therapistSelected.id}`, {
        nome,
        email,
        senha,
      });
      handleClose(true);
      setOpen(false);
      await afterEdit();

      await notificationSuccess({ title: "Terapeuta editado com sucesso!" });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box
          sx={{
            width: "528px",
            height: "590px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography fontWeight="600" fontSize={32} color="#4F4F4F">
              Editar terapeuta
            </Typography>
            <Divider sx={{ width: "428px" }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{ width: "380px", marginTop: "60px" }}
              id="outlined-basic"
              label="Nome"
              variant="outlined"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person sx={{ color: "#FF983D" }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ width: "380px", marginTop: "30px" }}
              id="outlined-basic"
              label="E-mail"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email sx={{ color: "#FF983D" }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ width: "380px", marginTop: "30px" }}
              id="outlined-basic"
              label="Senha"
              variant="outlined"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock sx={{ color: "#FF983D" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              onClick={() => editPatient()}
              sx={{
                marginTop: "60px",
                textTransform: "none",
                width: "261px",
                height: "57px",
                backgroundColor: "#FF983D",
              }}
            >
              <Typography fontWeight="600" color="#4F4F4F">
                Editar
              </Typography>
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
