import React from 'react';
import { AppRoutes } from './routes';
import { CssBaseline, Box } from '@mui/material'

function App() {
  return (
    <Box sx={{ flex: 1 }}>
      <CssBaseline />
      <AppRoutes />
    </Box>
  );
}

export default App;
