import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import Logo from "../../assets/logo2.svg";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import { api } from "../../server/api";
import { Itherapist } from "../../services/types/therapist";
import simpleNotificationConfirm from "../../services/Notification/SimpleConfirmation";

export const Home = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [therapistSelected, setTherapistSelected] = useState<Itherapist>();
  const [list, setList] = useState<Itherapist[]>([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listTherapists = async () => {
    try {
      const therapists = await api.get("/therapists");

      const therapistsInfo = therapists.data;

      const therapistFormmated = therapistsInfo.filter(
        (therapist: Itherapist) => !therapist.adm
      );

      setList(therapistFormmated);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    listTherapists();
  }, []);

  const handleDeleteTherapist = async (nome: string, id: string) => {
    const hasConfirmed = await simpleNotificationConfirm({
      title: `Deseja excluir o terapeuta ${nome}?`,
      text: "Essa ação não pode ser revertida!",
      confirmButtonColor: "red",
      iconColor: "red",
    });

    if (!hasConfirmed) return;

    try {
      await api.delete(`/therapist/${id}`);

      listTherapists();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          height: "120px",
          backgroundColor: "#FFF6EE",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <img src={Logo} alt="Logo" />
        <Box onClick={() => navigate("/register")} sx={{ cursor: "pointer" }}>
          <Typography fontWeight="700" color="#989898" fontSize={20}>
            Cadastrar terapeuta
          </Typography>
        </Box>
      </Box>
      <Box sx={{ paddingLeft: "100px", paddingTop: "60px" }}>
        <Typography fontWeight="700" color="#4F4F4F" fontSize={32}>
          Terapeutas cadastrados
        </Typography>
        <Box
          sx={{
            width: "1240px",
            maxHeight: "630px",
            overflowY: "scroll",
            border: "2px solid #595959",
            borderRadius: "10px",
            padding: "30px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {list.map((item) => (
            <Box
              sx={{
                marginBottom: "10px",
                width: "1149px",
                height: "168px",
                backgroundColor: "#F8F8F8",
                borderRadius: "10px",
                padding: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box key={item.id}>
                <Typography fontWeight="600" color="#989898" fontSize={24}>
                  Nome do Terapeuta: {item.nome}
                </Typography>
                <Typography
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                  fontWeight="600"
                  color="#989898"
                  fontSize={24}
                >
                  E-mail: {item.email}
                </Typography>
                {/* <Typography fontWeight="600" color="#989898" fontSize={24}>
                  Senha: {item.senha}
                </Typography> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Box
                  onClick={() => {
                    setTherapistSelected(item);
                    handleClickOpen();
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                >
                  <Edit sx={{ color: "#989898" }} />
                  <Typography fontWeight="500" color="#989898" fontSize={24}>
                    Editar
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDeleteTherapist(item.nome, item.id)}
                >
                  <Delete sx={{ color: "#FF0000" }} />
                  <Typography fontWeight="500" color="#FF0000" fontSize={24}>
                    Excluir
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Modal
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        setOpen={setOpen}
        therapistSelected={therapistSelected!}
        afterEdit={listTherapists}
      />
    </Box>
  );
};
