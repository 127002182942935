import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Logo from "../../assets/logo2.svg";
import Left from "../../assets/buttonleft.svg";
import { useNavigate } from "react-router-dom";
import { Email, Lock, Person } from "@mui/icons-material";
import { api } from "../../server/api";

export const RegisterTerapeut = () => {
  const navigate = useNavigate();
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  const createNewTherapist = async () => {
    try {
      await api.post(`/therapist`, { nome, email, senha });

      navigate("/home");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          height: "120px",
          backgroundColor: "#FFF6EE",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <img src={Logo} alt="Logo" />
        <Box onClick={() => navigate("/register")} sx={{ cursor: "pointer" }}>
          <Typography fontWeight="700" color="#FF983D" fontSize={20}>
            Cadastrar terapeuta
          </Typography>
        </Box>
      </Box>
      <Box sx={{ paddingLeft: "100px", paddingTop: "60px" }}>
        <Typography fontWeight="700" color="#4F4F4F" fontSize={32}>
          Terapeutas cadastrados
        </Typography>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            sx={{ width: "380px", marginTop: "60px" }}
            id="outlined-basic"
            placeholder="Insira o nome do terapeuta"
            label="Nome"
            variant="outlined"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person sx={{ color: "#FF983D" }} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={{ width: "380px", marginTop: "30px" }}
            id="outlined-basic"
            placeholder="Insira o login do terapeuta"
            label="E-mail"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email sx={{ color: "#FF983D" }} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={{ width: "380px", marginTop: "30px" }}
            id="outlined-basic"
            label="Senha"
            placeholder="Insira a senha do terapeuta"
            variant="outlined"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock sx={{ color: "#FF983D" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            onClick={createNewTherapist}
            sx={{
              marginTop: "60px",
              textTransform: "none",
              width: "261px",
              height: "57px",
              backgroundColor: "#FF983D",
            }}
          >
            <Typography fontWeight="600" color="#4F4F4F">
              Cadastrar terapeuta
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        onClick={() => navigate("/home")}
        sx={{
          cursor: "pointer",
          position: "absolute",
          bottom: 20,
          right: 20,
        }}
      >
        <img src={Left} alt="buttonVOltar" />
      </Box>
    </Box>
  );
};
