import axios from "axios";
import jwt_decode from "jwt-decode";
import { parseCookies, destroyCookie } from "nookies";
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export interface IToken {
  access_token?: string;
}

export function getUserId() {
  const { token } = parseCookies();
  // const token = localStorage.getItem('token');

  const tokenInfo = jwt_decode(token as string);

  console.log(tokenInfo);

  return tokenInfo;

  // return (tokenInfo as ItokenInfo)?.id_usuario;
}

api.interceptors.request.use(
  (config) => {
    const { token } = parseCookies();

    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => {
    destroyCookie(null, "user", { path: "/" });
    destroyCookie(null, "token", { path: "/" });
    // localStorage.removeItem('token');
    // localStorage.removeItem('user');
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (config) => {
    const { user } = parseCookies();
    const tokenLocal = user;

    if (tokenLocal) {
      const token = JSON.parse(tokenLocal) as IToken;

      config.headers = {
        Authorization: `Bearer ${token.access_token}`,
      };
    } else {
      console.log(config?.data);
    }

    return config;
  },
  (error) => {
    if (error.response?.status === 401 && error.config) {
      // localStorage.removeItem('user');
      destroyCookie(null, "user", { path: "/" });
      window.location.replace("/");
    }

    return Promise.reject(error);
  }
);
