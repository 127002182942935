import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { Email, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import { api } from "../../server/api";
import notificationError from "../../services/Notification/Error";
import { setCookie } from "nookies";

export const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  const checkCredentials = async () => {
    try {
      const token = await api.post(`/therapist/adm`, {
        email,
        senha,
      });

      setCookie(undefined, "token", token.data, {
        maxAge: 60 * 60 * 24 * 30,
        path: "/",
      });

      navigate("/home");
    } catch (error) {
      notificationError({ title: "Erro nas credenciais!" });

      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <img src={Logo} alt="" />
      <Box sx={{ width: "542px", marginTop: "30px" }}>
        <Typography
          textAlign="center"
          fontWeight="600"
          fontSize={25}
          color="#595959"
        >
          Boas vindas ao Jofi! Para continuar, por favor insira seu e-mail e
          senha.
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <TextField
          sx={{ width: "380px", marginTop: "60px" }}
          id="outlined-basic"
          label="E-mail"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={{ width: "380px", marginTop: "30px" }}
          id="outlined-basic"
          label="Senha"
          variant="outlined"
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
          }}
        />
        <Button
          onClick={() => checkCredentials()}
          sx={{
            textTransform: "none",
            width: "261px",
            height: "57px",
            backgroundColor: "#FF983D",
            marginTop: "20px",
          }}
        >
          <Typography fontWeight="600" color="#4F4F4F">
            Entrar
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
