import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { Home } from '../pages/Home';
import { Login } from '../pages/Login';
import { RegisterTerapeut } from '../pages/RegisterTerapeut';

export const AppRoutes = () => {
  // Lembrar de alterar as rotas "/admin/agenda"
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path='/home' element={<Home />} />
      <Route path='/register' element={<RegisterTerapeut />} />
    </Routes>
  )
}
