import Swal from 'sweetalert2';
import { ICustomNotification } from '../types/notification';

export default function notificationError({
  title,
  text,
}: ICustomNotification) {
  return Swal.fire({
    icon: 'error',
    confirmButtonColor: 'red',
    denyButtonColor: 'red',
    cancelButtonColor: 'red',
    title,
    text,
  });
}
